import { memo, ChangeEventHandler, JSX } from "react";

import styled, { keyframes } from "styled-components";

import Input from "../../../UI/Input";
import Select from "../../../UI/Select";
import TextLG from "../../../UI/TextLG";
import TextSM from "../../../UI/TextSM";
import TextXL from "../../../UI/TextXL";
import TextXXL from "../../../UI/TextXXL";

const slide = (panelWidth?: number) => keyframes`
0% {
  transform: translateY(${panelWidth}vh);
}
100% {
  transform: translateY(0px);
}`;

const HelpUI = styled.div`
  position: absolute;
  z-index: 100;
  bottom: 0;
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  align-items: flex-end;
  background-color: ${({ theme }) => theme.colors.black};
`;

HelpUI.displayName = "HelpUI";

const ContentUI = styled.div`
  position: relative;
  z-index: 102;
  bottom: 0;
  display: flex;
  width: 100%;
  height: 90vh;
  /* this is only interpreted by recent iOS Safari ! */
  height: 94dvh; /* stylelint-disable-line unit-no-unknown, unit-allowed-list */
  box-sizing: border-box;
  flex-direction: column;
  padding: 24px;
  padding-bottom: 12px;
  animation: ${slide(68)} 0.8s cubic-bezier(0.8, 1, 0.17, 1);
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 16px 16px 0px 0px;

  &::after {
    position: absolute;
    z-index: 102;
    top: -10px;
    right: 0;
    left: 0;
    display: block;
    width: 92%;
    height: 20px;
    margin-right: auto;
    margin-left: auto;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 10px 10px 0px 0px;
    clear: both;
    content: "";
    opacity: 0.4;
  }
`;

ContentUI.displayName = "ContentUI";

const TopUI = styled.div`
  display: flex;
  width: 100%;
  align-items: baseline;
  justify-content: space-between;
  margin-bottom: 16px;
`;

TopUI.displayName = "TopUI";

const CancelUI = styled.div`
  p {
    font-weight: 500;
  }
`;

CancelUI.displayName = "CancelUI";

const HintUI = styled(TextSM)<{ $align?: string }>`
  margin-top: 6px;
  margin-bottom: 16px;
  color: ${({ theme }) => theme.palette.neutral[700]};
  text-align: ${({ $align }) => ($align ? $align : "left")};
`;

HintUI.displayName = "HintUI";

const RequiredUI = styled(TextSM)`
  align-self: flex-end;
  margin: 6px 0px;
  color: ${({ theme }) => theme.palette.secondary[500]};
`;

RequiredUI.displayName = "RequiredUI";

const FakeInputUI = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 24px;
  border: ${({ theme }) => `1px solid ${theme.palette.secondary[500]}`};
  margin-bottom: 8px;
  background-color: ${({ theme }) => theme.palette.secondary[50]};
  border-radius: 6px;
  color: ${({ theme }) => theme.palette.secondary[500]};
`;

FakeInputUI.displayName = "FakeInputUI";

const InputUI = styled(Input)`
  margin-bottom: 6px;
`;

InputUI.displayName = "InputUI";

const TextAreaUI = styled.textarea`
  flex: 1;
  padding: 18px 24px;
  border: ${({ theme }) => `1px solid ${theme.palette.neutral[300]}`};
  margin-top: 8px;
  margin-bottom: 12px;
  border-radius: 6px;
  font-family: "Plus Jakarta Sans", sans-serif;
  resize: none;

  &::placeholder {
    color: ${({ theme }) => theme.palette.neutral[600]};
    font-family: "Plus Jakarta Sans", sans-serif;
  }
`;

TextAreaUI.displayName = "TextAreaUI";

const ButtonUI = styled.button`
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  padding: 10px 0px;
  border: none;
  margin-top: 24px;
  background-color: ${({ theme }) => theme.palette.secondary[500]};
  border-radius: 10px;
  p {
    color: ${({ theme }) => theme.colors.white};
  }
  &:disabled {
    background-color: ${({ theme }) => theme.palette.neutral[300]};
  }
`;

ButtonUI.displayName = "ButtonUI";

export type HelpProps = {
  formData: {
    message?: string;
    option?: string;
  };
  adId: string | null;
  userId: string | null;
  options?: Array<{ label: string; value: string }>;
  onChange: ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
  >;
  onSubmit?: () => void;
  onDrawerToggle: () => void;
};

const defaultOptions = new Array<{ label: string; value: string }>();
const defaultOnDrawerToggle = (): void => void 0;
const defaultOnSubmit = (): void => void 0;

function Help({
  adId,
  userId,
  formData,
  onChange,
  options = defaultOptions,
  onSubmit = defaultOnSubmit,
  onDrawerToggle = defaultOnDrawerToggle,
}: HelpProps): JSX.Element {
  const { message, option } = formData;
  return (
    <HelpUI>
      <ContentUI>
        <TopUI>
          <TextXXL>Get Help</TextXXL>
          <CancelUI onClick={onDrawerToggle}>
            <TextLG>Cancel</TextLG>
          </CancelUI>
        </TopUI>
        <Select
          options={options}
          value={option}
          onChange={onChange}
          placeholder="Please select your offer"
        />
        <HintUI>Select the offer you need help with.</HintUI>
        <FakeInputUI>User ID: #{userId}</FakeInputUI>
        <FakeInputUI>Advertising ID: #{adId}</FakeInputUI>
        <TextAreaUI
          name="message"
          value={message}
          onChange={onChange}
          placeholder="Your Message..."
        />
        <HintUI $align="center">
          Please add proof (screenshot, emails) in the next screen in attachment
          of your request
        </HintUI>
        <ButtonUI onClick={onSubmit} disabled={!adId}>
          <TextXL>Send</TextXL>
        </ButtonUI>
        <HintUI $align="center">
          After you send your message, an expert will reach out to your email
          within the next 48 hours.
        </HintUI>
      </ContentUI>
    </HelpUI>
  );
}

export default memo(Help);
