import { useEffect } from "react";

import { useCallRestApi } from "@mobsuccess-devops/react-data-context";

import { SHA1 } from "crypto-js";
import { isDesktop } from "react-device-detect";

import { useSearchParams } from "../../../../../packages/react-router/src";
import { OfferFromApi, Offer } from "../../types/offers";
import {
  useOffersAtom,
  useIsFetchingOffersAtom,
  useGetOffers,
  useGetIsFetchingOffers,
  useDiscoveryOffersAtom,
  useIsFetchingDiscoveryOffersAtom,
  useGetDiscoveryOffers,
  useGetIsFetchingDiscoveryOffers,
} from "../atoms/offers";
import { parseHtmlEntities } from "../utils/parseHtml";

const defaultSupportEmail = "support@farly.io";

const queryParams = [
  "pubid",
  "timestamp",
  "hash",
  "userid",
  "device",
  "devicemodel",
  "os_version",
  "is_tablet",
  "country",
  "ip",
  "connection_type",
  "locale",
  "pub0",
  "pub1",
  "idfa",
  "gaid",
  "idfasha1",
  "gaidsha1",
  "zip",
  "user_age",
  "user_gender",
  "user_signup_timestamp",
  "from",
] as const;

export function useFetchOffers(): void {
  const callRestApi = useCallRestApi<unknown, OfferFromApi[]>();
  const [, setOffers] = useOffersAtom();
  const [, setIsFetching] = useIsFetchingOffersAtom();
  const [params] = useSearchParams();

  const getOffers = useGetOffers();
  const getIsFetchingOffers = useGetIsFetchingOffers();

  useEffect(() => {
    const paramsObject = queryParams.reduce<Record<string, string>>(
      (acc, current) => {
        const param = params.get(current);
        if (param) {
          acc[current] = param;
        }
        return acc;
      },
      {}
    );
    (async () => {
      const offers = await getOffers();
      const isFetching = await getIsFetchingOffers();

      if (isFetching || offers.length) {
        return;
      }

      setIsFetching(true);
      const result = await callRestApi("GET offerwall /api/feed/v2/incent", {
        participation: "1",
        ...paramsObject,
      });
      setIsFetching(false);
      if (result.status === 200 && typeof result.data === "object") {
        setOffers(orderOffers(result.data));
      }
    })();
  }, [
    callRestApi,
    setOffers,
    setIsFetching,
    params,
    getOffers,
    getIsFetchingOffers,
  ]);
}

export function useFetchDiscoveryOffers(): void {
  const callRestApi = useCallRestApi<
    "GET offerwall /api/feed/v2/nonincent",
    OfferFromApi[]
  >();
  const [, setDiscoveryOffers] = useDiscoveryOffersAtom();
  const [, setIsFetching] = useIsFetchingDiscoveryOffersAtom();
  const [params] = useSearchParams();

  const getOffers = useGetDiscoveryOffers();
  const getIsFetchingOffers = useGetIsFetchingDiscoveryOffers();

  useEffect(() => {
    const paramsObject = queryParams.reduce<Record<string, string | number>>(
      (acc, current) => {
        const param = params.get(current);
        if (param) {
          acc[current] = param;
        }
        return acc;
      },
      {}
    );
    let paramsFallbacks: Partial<
      Record<
        typeof queryParams[number],
        typeof paramsObject[keyof typeof paramsObject]
      >
    > = {};

    if (params.get("fallback")) {
      const timestamp = Math.round(Date.now() / 1000);
      paramsFallbacks = {
        pubid: import.meta.env.VITE_FALLBACK_PUB_ID,
        timestamp,
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        hash: SHA1(
          `${timestamp}${import.meta.env.VITE_FALLBACK_API_KEY}`
        ) as unknown as string,
        userid: crypto.randomUUID(),
      };
      if (isDesktop) {
        paramsFallbacks.device = "desktop"; // the back end will detect ios or android from the user agent, but not desktop
      }
    }

    (async () => {
      const offers = await getOffers();
      const isFetching = await getIsFetchingOffers();

      if (isFetching || offers.length) {
        return;
      }

      setIsFetching(true);
      const result = await callRestApi(
        "GET offerwall /api/feed/v2/" +
          (params.get("fallback") ? "all" : "nonincent"),
        {
          participation: "1",
          ...paramsFallbacks,
          ...paramsObject,
        }
      );
      setIsFetching(false);
      if (result.status === 200 && typeof result.data === "object") {
        setDiscoveryOffers(orderOffers(result.data));
      }
    })();
  }, [
    callRestApi,
    setDiscoveryOffers,
    setIsFetching,
    params,
    getOffers,
    getIsFetchingOffers,
  ]);
}

function orderOffers(data?: OfferFromApi[]): Offer[] {
  const adaptedOffers = adaptOffers(data ?? []);
  const firsts: typeof adaptedOffers = [];
  const others: typeof adaptedOffers = [];
  adaptedOffers.forEach((offer) => {
    if (offer.hasSurveyTag) {
      firsts.push(offer);
    } else {
      others.push(offer);
    }
  });
  return [...firsts, ...others];
}

export function adaptOffers(offers: OfferFromApi[]): Offer[] {
  return offers.map((offer) => {
    const categories = offer.categories ?? [];
    return {
      id: offer.id,
      name: offer.name,
      os: offer?.os,
      link: offer?.link,
      icone: offer.icone,
      small_description: parseHtmlEntities(offer.small_description),
      actions:
        offer.actions?.map((action) => ({
          id: action.id,
          amount: action.amount,
          text: parseHtmlEntities(action.text),
        })) || [],
      hasBoostTag: categories.includes("appMarketing_boost"),
      hasPurchaseTag: categories.includes("appmarketing_purchase"),
      hasFreeTag: categories.includes("appMarketing_free"),
      hasEasyTag: categories.includes("appMarketing_quick"),
      hasMediumTag: categories.includes("appMarketing_medium"),
      hasHardTag: categories.includes("appMarketing_hard"),
      hasWebTag: categories.includes("appMarketing_web"),
      hasWebMobileTag: categories.includes("appMarketing_webmobile"),
      hasAndroidTag: categories.includes("appMarketing_android"),
      hasIosTag: categories.includes("appMarketing_ios"),
      hasDiscoveryTag: categories.includes("appMarketing_discovery"),
      hasShoppingTag: categories.includes("appMarketing_shopping"),
      hasActionsTag: categories.includes("appMarketing_action"),
      hasSurveyTag: categories.includes("appMarketing_survey"),
      hasCasinoTag: categories.includes("appMarketing_casino"),
      hasGamesTag: categories.includes("appMarketing_games"),
      hasRegistrationTag: categories.includes("appMarketing_registration"),
      hasPopularTag: categories.includes("appMarketing_popular"),
      hasNewTag: categories.includes("appMarketing_new"),
      supportEmail: offer.support_email || defaultSupportEmail,
      category: offer.categories?.[0] || "",
      priceApp: offer.price_app,
      status: offer.status || "todo",
      rewardAmount: offer.reward_amount,
      moneyIcon: offer.money_icon,
      moneyName: parseHtmlEntities(offer.money_name),
    };
  });
}
