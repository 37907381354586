import { JSX, memo, useMemo } from "react";

import { LargeOfferList } from "../components/LargeOfferList/LargeOfferList";
import { SmallOfferList } from "../components/SmallOfferList/SmallOfferList";
import { PageLayout } from "../components/UI/PageLayout/PageLayout";
import { useFetchOffers } from "../features/actions/offers";
import { useOffersAtom } from "../features/atoms/offers";

function Home(): JSX.Element {
  useFetchOffers();
  const [offers] = useOffersAtom();

  const popularOffers = useMemo(
    () => offers.filter(({ hasPopularTag }) => hasPopularTag),
    [offers]
  );

  const surveyOffers = useMemo(
    () => offers.filter(({ hasSurveyTag }) => hasSurveyTag),
    [offers]
  );

  const allOffers = useMemo(
    () =>
      offers.filter(
        ({ status }) => status !== "completed" && status !== "in_progress"
      ),
    [offers]
  );

  return (
    <PageLayout>
      {popularOffers.length ? (
        <SmallOfferList title="Popular Offers 🔥" offers={popularOffers} />
      ) : null}
      {surveyOffers.length ? (
        <LargeOfferList title="Surveys 📋" offers={surveyOffers} />
      ) : null}
      <LargeOfferList title="All Offers 🎉" offers={allOffers} />
    </PageLayout>
  );
}

export default memo(Home);
