import { memo, ChangeEvent, JSX } from "react";

import styled from "styled-components";

const InputUI = styled.input`
  display: flex;
  align-items: center;
  padding: 13px 24px;
  border: ${({ theme }) => `1px solid ${theme.palette.neutral[300]}`};
  margin-bottom: 8px;
  border-radius: 6px;
  color: ${({ theme }) => theme.colors.black};

  &::placeholder {
    color: ${({ theme }) => theme.palette.neutral[600]};
  }
`;

InputUI.displayName = "InputUI";

export type InputProps = {
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  name?: string;
  placeholder?: string;
};

function Input({
  onChange,
  value,
  name,
  placeholder,
}: InputProps): JSX.Element {
  return (
    <InputUI
      name={name}
      onChange={onChange}
      value={value}
      placeholder={placeholder}
    />
  );
}

export default memo(Input);
