import { memo, useCallback, useEffect, useState, JSX } from "react";

import { useSelectedOfferAtom } from "../../features/atoms/offers";

import ActionsPopup from "./ActionsPopup";

function ActionsPopupBase(): JSX.Element {
  const [offer, setOffer] = useSelectedOfferAtom();
  const [delayedOffer, setDelayedOffer] = useState(offer);
  const [isEligibilityOpen, setIsEligibilityOpen] = useState(false);

  const handleBackdropClick = useCallback(() => {
    setOffer(null);
  }, [setOffer]);

  const handleButtonClick = useCallback(() => {
    setOffer(null);
    if (offer?.link) {
      window.open(offer?.link);
    }
  }, [setOffer, offer]);

  useEffect(() => {
    if (offer) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [offer]);

  // delay offer set to null for a while to avoid flickering
  useEffect(() => {
    if (offer) {
      setDelayedOffer(offer);
    } else {
      setTimeout(() => {
        setDelayedOffer(null);
      }, 500);
    }
  }, [offer]);

  const handleEligibilityOpen = useCallback(() => {
    setIsEligibilityOpen(!isEligibilityOpen);
  }, [isEligibilityOpen]);

  return (
    <ActionsPopup
      offer={delayedOffer}
      onBackdropClick={handleBackdropClick}
      onButtonClick={handleButtonClick}
      display={offer !== null}
      isEligibilityOpen={isEligibilityOpen}
      onEligibilityOpen={handleEligibilityOpen}
    />
  );
}

export default memo(ActionsPopupBase);
