import { memo, JSX } from "react";

import { ReactComponent as ArrowRightIcon } from "@mobsuccess-devops/streamline/regular/52-Arrows-Diagrams/01-Arrows/arrow-right-1.svg";

import { isDesktop, isMobile } from "react-device-detect";
import styled from "styled-components";

import { Offer } from "../../types/offers";
import DesktopLink from "../DesktopLink";
import Missions from "../Missions";
import { TagList } from "../TagList/TagList";
import TextLG from "../UI/TextLG";
import TextMD from "../UI/TextMD";
import TextXL from "../UI/TextXL";
import TextXS from "../UI/TextXS";

import { OfferActionButton } from "./OfferActionButton/OfferActionButton";

const ActionsPopupUI = styled.div`
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100vw;
  height: 100dvh;
  box-sizing: border-box;
  overflow-x: hidden;
  pointer-events: none;
`;

ActionsPopupUI.displayName = "ActionsPopupUI";

const BackdropUI = styled.div<{ $display: boolean }>`
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  ${({ $display }) =>
    $display
      ? `background-color: rgba(0, 0, 0, 0.4);`
      : `background-color: transparent;`}
  pointer-events: ${({ $display }) => ($display ? `auto` : `none`)};
  transition: background-color 0.5s ease-in-out;
`;

BackdropUI.displayName = "BackdropUI";

const PopupUI = styled.div<{ $display: boolean }>`
  position: absolute;
  z-index: 200;
  top: 50%;
  left: 50%;
  width: calc(100% - 48px);
  max-width: 500px;
  box-sizing: border-box;
  height: fit-content;
  max-height: calc(
    100% - calc(env(safe-area-inset-top) + env(safe-area-inset-bottom)) - 48px
  );
  overflow-y: auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
  background-color: #fff;
  border-radius: 8px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15));
  pointer-events: auto;
  transform: ${({ $display }) =>
    $display ? `translate(-50%, -50%)` : `translate(200%, -50%)`};
  transition: transform 0.5s ease-in-out;
`;

PopupUI.displayName = "PopupUI";

type LogoContainerUIProps = {
  $icon?: string;
};

const LogoContainerUI = styled.div<LogoContainerUIProps>`
  display: flex;
  justify-content: center;
  & > span {
    width: 64px;
    height: 64px;
    background-image: ${({ $icon }) => `url(${$icon})`};
    background-size: cover;
    border-radius: 8px;
  }
`;

LogoContainerUI.displayName = "LogoContainerUI";

const OfferInfosUI = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
`;

OfferInfosUI.displayName = "OfferInfosUI";

const OfferTextUI = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 12px;
`;

OfferTextUI.displayName = "OfferTextUI";

const WarningTextUI = styled(TextMD)`
  color: ${({ theme }) => theme.palette.warning[500]};
`;

WarningTextUI.displayName = "WarningTextUI";

const WarningUI = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  & > svg {
    margin-right: 10px;
    color: ${({ theme }) => theme.palette.warning[500]};
  }
`;

WarningUI.displayName = "WarningUI";

const MissionsTextUI = styled(TextXL)`
  color: ${({ theme }) => theme.palette.neutral[900]};
`;

MissionsTextUI.displayName = "MissionsTextUI";

const DescriptionTextUI = styled(TextXS)`
  margin-top: 4px;
  margin-bottom: 8px;
  color: ${({ theme }) => theme.palette.neutral[500]};
  font-weight: 600;
`;

DescriptionTextUI.displayName = "DescriptionTextUI";

const BadgesContainerUI = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 4px;
  overflow-x: scroll;
  & > * {
    &:not(:last-child) {
      margin-right: 4px;
    }
  }
`;

BadgesContainerUI.displayName = "BadgesContainerUI";

const WrapperQRCodeUI = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

WrapperQRCodeUI.displayName = "WrapperQRCodeUI";

const QRCodeTextUI = styled.span`
  margin-right: 20px;
  color: #131516;
  font-size: 15px;
  font-weight: 500;
  line-height: 140%;
`;

QRCodeTextUI.displayName = "QRCodeTextUI";

const EligibilityWrapperUI = styled.div`
  margin-top: 16px;
  background-color: #f1f2f8;
  border-radius: 8px;
  cursor: pointer;
`;
EligibilityWrapperUI.displayName = "EligibilityWrapperUI";

const EligibilityPrimaryContentUI = styled.div`
  display: flex;
  height: 44px;
  align-items: center;
  margin: 0 12px;
`;
EligibilityPrimaryContentUI.displayName = "EligibilityPrimaryContentUI";

type EligibilityIconUIProps = {
  $isArrowDown?: boolean;
};

const EligibilityIconUI = styled(ArrowRightIcon)<EligibilityIconUIProps>`
  height: 15px;
  margin-right: 12px;
  ${({ $isArrowDown }) => $isArrowDown && `transform: rotate(90deg);`}
`;
EligibilityIconUI.displayName = "EligibilityIconUI";

const EligibilityPrimaryTextUI = styled.span`
  color: #131516;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
`;
EligibilityPrimaryTextUI.displayName = "EligibilityPrimaryTextUI";

const EligibilitySubContentUI = styled.ul`
  padding: 10px 25px;
`;
EligibilitySubContentUI.displayName = "EligibilitySubContentUI";

const EligibilitySubItemTextUI = styled.li`
  color: #131516;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 11px;
  font-weight: 500;
  line-height: 140%;
`;
EligibilitySubItemTextUI.displayName = "EligibilitySubItemTextUI";

export type ActionsPopupProps = {
  offer?: Offer | null;
  display: boolean;
  onBackdropClick?: () => void;
  onButtonClick?: () => void;
  isEligibilityOpen?: boolean;
  onEligibilityOpen?: () => void;
};

function ActionsPopup({
  offer,
  onBackdropClick,
  onButtonClick,
  display,
  isEligibilityOpen,
  onEligibilityOpen,
}: ActionsPopupProps): JSX.Element | null {
  if (!offer) {
    return null;
  }

  return (
    <ActionsPopupUI>
      <PopupUI $display={display}>
        <OfferInfosUI>
          <LogoContainerUI $icon={offer?.icone}>
            <span />
          </LogoContainerUI>
          <OfferTextUI>
            <TextLG>
              {offer?.name}
              {isDesktop && offer?.os === "ios" && " - IOS"}
              {isDesktop && offer?.os === "android" && " - Android"}
            </TextLG>
            <DescriptionTextUI>{offer?.small_description}</DescriptionTextUI>
            <TagList offer={offer} />
          </OfferTextUI>
        </OfferInfosUI>
        <MissionsTextUI>Missions</MissionsTextUI>
        <Missions offer={offer} />
        {isDesktop && offer?.link && <DesktopLink link={offer.link} />}
        {isMobile && (
          <OfferActionButton offer={offer} onClick={onButtonClick} />
        )}
        <EligibilityWrapperUI onClick={onEligibilityOpen}>
          <EligibilityPrimaryContentUI>
            <EligibilityIconUI $isArrowDown={isEligibilityOpen} />
            <EligibilityPrimaryTextUI>
              Eligibility terms
            </EligibilityPrimaryTextUI>
          </EligibilityPrimaryContentUI>
          {isEligibilityOpen && (
            <EligibilitySubContentUI>
              <EligibilitySubItemTextUI>
                You must be a new user.
              </EligibilitySubItemTextUI>
              <EligibilitySubItemTextUI>
                You can&apos;t use VPN or proxy.
              </EligibilitySubItemTextUI>
              <EligibilitySubItemTextUI>
                You can&apos;t use an emulator of any kind.
              </EligibilitySubItemTextUI>
              <EligibilitySubItemTextUI>
                Offer should be completed within 30 days after install.
              </EligibilitySubItemTextUI>
              <EligibilitySubItemTextUI>
                We recommend you to use same device and location and to open the
                app right after you install it.
              </EligibilitySubItemTextUI>
              <EligibilitySubItemTextUI>
                Please note it may take a few hours to be unlocked after data
                validation.
              </EligibilitySubItemTextUI>
            </EligibilitySubContentUI>
          )}
        </EligibilityWrapperUI>
      </PopupUI>
      <BackdropUI $display={display} onClick={onBackdropClick} />
    </ActionsPopupUI>
  );
}

export default memo(ActionsPopup);
