import {
  memo,
  useState,
  useCallback,
  ChangeEventHandler,
  useMemo,
  useEffect,
  JSX,
} from "react";

import { useSearchParam } from "../../../../../../../packages/react-router/src";
import { useFetchOffers } from "../../../../features/actions/offers";
import { useOffersAtom } from "../../../../features/atoms/offers";
import { Offer } from "../../../../types/offers";

import Help from "./Help";

export type HelpBaseProps = {
  drawerDisplay: boolean;
  onDrawerToggle: () => void;
};

function HelpBase({
  drawerDisplay,
  onDrawerToggle,
}: HelpBaseProps): JSX.Element | null {
  useFetchOffers();

  const [userId] = useSearchParam("userid", {
    defaultValue: "",
  });

  const [offers] = useOffersAtom();

  const offersOptions = useMemo(() => {
    return offers
      .filter(
        (offer) => offer.status === "in_progress" && !offer.hasDiscoveryTag
      )
      .map((offer) => ({
        label: offer.name,
        value: offer.id,
      }));
  }, [offers]);

  const [options, setOptions] = useState(offersOptions);

  useEffect(() => {
    setOptions(offersOptions);
  }, [offersOptions]);

  const [formData, setFormData] = useState({
    message: "",
    option: undefined,
  });

  const selectedOffer: Offer | null = useMemo(() => {
    if (formData.option) {
      return offers.find((offer) => offer.id === formData.option) ?? null;
    }
    return null;
  }, [formData.option, offers]);

  const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (e) => {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    },
    [formData, setFormData]
  );

  const handleSubmit = useCallback(() => {
    const offerId = formData?.option;
    const subject = encodeURIComponent(
      `Help ${offerId ? `with offer ${offerId}` : ""}`
    );
    const bodyHeading = `Farly Support Request ${
      offerId ? `- Reference ${offerId}` : ""
    }\n\n`;
    const body = encodeURIComponent(
      `${bodyHeading}${formData.message}\n\n#${userId}`
    );
    const email = selectedOffer?.supportEmail || "support@farly.io";
    window.location.href = `mailto:${email}?subject=${subject}&body=${body}`;
  }, [userId, formData, selectedOffer]);

  return drawerDisplay ? (
    <Help
      options={options}
      formData={formData}
      onChange={handleChange}
      onSubmit={handleSubmit}
      onDrawerToggle={onDrawerToggle}
      userId={userId ?? ""}
      adId={formData?.option ?? null}
    />
  ) : null;
}

export default memo(HelpBase);
