import { ChangeEventHandler, memo, JSX } from "react";

import styled from "styled-components";

const SelectUI = styled.select`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  padding: 13px 24px;
  border: none;
  border-radius: 6px;
  color: ${({ theme }) => theme.colors.black};
  outline: none;
`;

SelectUI.displayName = "SelectUI";

const WrapperUI = styled.div`
  position: relative;
  display: flex;
  padding-right: 16px;
  border: ${({ theme }) => `1px solid ${theme.palette.neutral[300]}`};
  border-radius: 6px;
`;

WrapperUI.displayName = "WrapperUI";

export type SelectProps = {
  onChange?: ChangeEventHandler<HTMLSelectElement>;
  value?: string;
  name?: string;
  placeholder?: string;
  options?: Array<{ label: string; value: string }>;
};

const defaultOptions = new Array<{ label: string; value: string }>();

function Select({
  onChange,
  value,
  placeholder,
  options = defaultOptions,
}: SelectProps): JSX.Element {
  return (
    <WrapperUI>
      <SelectUI
        name="option"
        onChange={onChange}
        value={value}
        placeholder={placeholder}
      >
        <option value="">{placeholder}</option>
        {options.map((option) => {
          return (
            <option value={option.value} key={option.value}>
              {option.label}
            </option>
          );
        })}
      </SelectUI>
    </WrapperUI>
  );
}

export default memo(Select);
