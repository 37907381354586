import { memo, JSX } from "react";

import styled from "styled-components";

import { ReactComponent as GoldCoinIcon } from "../../assets/icons/custom/gold-coin.svg";
import { Offer } from "../../types/offers";
import TextMD from "../UI/TextMD";
import TextSM from "../UI/TextSM";
import TextXS from "../UI/TextXS";

const RowUI = styled.div`
  display: flex;
  height: 80px;
  flex-direction: row;
  align-items: center;
`;

RowUI.displayName = "RowUI";

const CircleContainerUI = styled.div`
  width: 30px;
  min-width: 30px;
`;

CircleContainerUI.displayName = "CircleContainerUI";

const CircleUI = styled.div<{ $diameter: number }>`
  position: relative;
  width: ${({ $diameter }) => `${$diameter}px`};
  height: ${({ $diameter }) => `${$diameter}px`};
  box-sizing: border-box;
  margin-left: ${({ $diameter }) => 7.5 - $diameter / 2 + "px"};
  background-color: ${({ theme }) => theme.palette.secondary[500]};
  border-radius: 50%;
`;

CircleUI.displayName = "CircleUI";

const VerticalLineUI = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 2.5px;
  height: 80px;
  background-color: ${({ theme }) => theme.palette.secondary[500]};
  transform: translateX(-50%);
`;

VerticalLineUI.displayName = "VerticalLineUI";

export const MoneyIconContainerUI = styled.img`
  width: 20px;
`;

const RightUI = styled.div`
  margin-left: auto;
  padding-left: 12px;
`;

const BadgeUI = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 8px;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.palette.secondary[500]};
  color: white;
  gap: 2px;
`;

export type MissionsProps = {
  offer: Offer;
  isFallback: boolean;
};

function Missions({ offer, isFallback }: MissionsProps): JSX.Element {
  return (
    <div>
      <div>
        {offer.actions.map((action, index) => (
          <RowUI key={action.id}>
            <CircleContainerUI>
              {index < offer.actions.length - 1 ? (
                <CircleUI $diameter={7.5}>
                  <VerticalLineUI />
                </CircleUI>
              ) : (
                <CircleUI $diameter={15} />
              )}
            </CircleContainerUI>
            {action.text.length < 140 ? (
              <TextMD>{action.text}</TextMD>
            ) : action.text.length < 170 ? (
              <TextSM>{action.text}</TextSM>
            ) : (
              <TextXS>{action.text}</TextXS>
            )}
            {action.amount && !isFallback ? (
              <RightUI>
                <BadgeUI>
                  <TextXS>+{action.amount}</TextXS>
                  {offer.moneyIcon ? (
                    <MoneyIconContainerUI src={offer.moneyIcon} />
                  ) : (
                    <GoldCoinIcon />
                  )}
                </BadgeUI>
              </RightUI>
            ) : null}
          </RowUI>
        ))}
      </div>
    </div>
  );
}

export default memo(Missions);
